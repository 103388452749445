import { createMuiTheme } from '@material-ui/core/styles'
const theme = createMuiTheme({
  palette: {
    primary: {
      '50': '#21412a',
      '100': '#21412a',
      '200': '#21412a',
      '300': '#21412a',
      '400': '#21412a',
      '500': '#21412a',
      '600': '#21412a',
      '700': '#21412a',
      '800': '#21412a',
      '900': '#21412a',
      A100: '#21412a',
      A200: '#21412a',
      A400: '#21412a',
      A700: '#21412a',
      contrastDefaultColor: 'light'
    },
    secondary: {
      '50': '#fff',
      '100': '#fff',
      '200': '#fff',
      '300': '#fff',
      '400': '#fff',
      '500': '#fff',
      '600': '#fff',
      '700': '#fff',
      '800': '#fff',
      '900': '#fff',
      A100: '#fff',
      A200: '#fff',
      A400: '#fff',
      A700: '#fff',
      contrastDefaultColor: 'light'
    }
  }
})

export default theme
